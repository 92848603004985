import './swegon-slider.js';
import { Elm } from "./Main.elm";

const app = Elm.Main.init({
  node: document.querySelector("main")
});

app.ports.outgoing.subscribe(msg => {
  window.ahu2020.outgoing(JSON.stringify(msg));
});

window.ahu2020 = {};

/**
 * Outgoing messages: Web -> Native (Android, iOS).
 * Function should be set by native host.
 * @param msg json payload
 */
window.ahu2020.outgoing = msg => {
  console.error(
    "[MSG OUT] - Handler not set for outgoing messages - using default handler"
  );
};

/**
 * Incoming messages: Native (Android, iOS) -> Web.
 * Should be called by native host
 * @param msg json payload
 */
window.ahu2020.incoming = msg => {
  app.ports.onIncoming.send(msg);
};
